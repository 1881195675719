import { Avatar, Box, Stack, Typography } from '@mui/material'
import theme from '@/theme.ts'
import {
  DeleteOutline,
  FacebookOutlined,
  Google as GoogleIcon,
  Person,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { AUTH0_AUDIENCE } from '@/constants.ts'
import axios from 'axios'
import { PrimaryDeleteButton } from '@/components/ui/base/buttons/buttons.tsx'
import Tooltip from '@mui/material/Tooltip'
import {
  Auth0UserExpanded,
  Auth0UserIdentity,
  Auth0UserProfileDataMinimal,
} from '@/types.ts'

import { IdToken } from '@auth0/auth0-react'
import {
  getExpandedAuth0UserDetails,
  linkAccountToPrimaryAuth0User,
  unlinkAccountFromPrimaryAuth0User,
} from '@/api/auth0.ts'
import { PlusIcon } from 'lucide-react'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { StyledAddIcon } from '@/components/ui/base/buttons/edit-icon-pencil-outlined.tsx'

interface ExtendedIdToken extends IdToken {
  __raw: string
  sub: string
}

const LinkedAccounts = () => {
  const [linkedAccounts, setLinkedAccounts] = useState<Auth0UserIdentity[]>([])
  const { getAccessTokenSilently, user } = useAuth0()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    updateLinkedAccounts()
  }, [])

  const updateLinkedAccounts = async () => {
    const accounts = (await getAuth0UserLinkedAccounts(
      null
    )) as Auth0UserIdentity[]
    setLinkedAccounts(accounts)
  }

  const getAuth0UserManagementAccessToken = async () => {
    return (await getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
        scope:
          'openid profile email read:current_user update:current_user_identities',
      },
    })) as string
  }

  const getAuth0UserLinkedAccounts = async (token: string | null) => {
    // get the access token for current user to call management api
    const accessToken = token || (await getAuth0UserManagementAccessToken())

    // Call management api with the access token to get linked accounts
    const userDetailsResponse = (await getExpandedAuth0UserDetails(
      user?.sub as string,
      accessToken
    )) as Auth0UserExpanded

    // retrieve the secondary identities from the response
    const accounts = userDetailsResponse.identities

    // linked account are stored in the identities array - account in 0th index is the primary user
    // auth0 does not expand upon the primary user's details in this array, so we need to add it
    // from the user object in the response
    if (accounts?.[0]) {
      const { email, email_verified, family_name, given_name, name, picture } =
        userDetailsResponse

      accounts[0] = {
        ...accounts[0],
        primary: true,
        profileData: {
          email,
          email_verified,
          family_name,
          given_name,
          name,
          picture,
        } as Auth0UserProfileDataMinimal,
      } as Auth0UserIdentity
    }

    return accounts
  }

  const getSecondaryAuth0UserDetails = async (connection: string) => {
    // Create a new Auth0 client instance for secondary login
    const secondaryAuth0Client = new Auth0Client({
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })

    try {
      // Login with the new instance
      await secondaryAuth0Client.loginWithPopup({
        authorizationParams: {
          connection: connection,
          prompt: 'consent',
          scope: 'openid profile email',
        },
      })

      // Get the ID token from the secondary login
      const { __raw: secondaryIdToken, sub: secondaryUserId } =
        (await secondaryAuth0Client.getIdTokenClaims()) as ExtendedIdToken

      return { secondaryUserId, secondaryUserIdToken: secondaryIdToken }
    } catch (error) {
      console.error('Secondary login error:', error)
      throw error
    }
  }

  const handleUnlinkAccount = async (
    provider: string,
    secondaryUserId: string
  ) => {
    try {
      handleMenuClose()

      const primaryUserId = user?.sub as string

      const primaryUserToken = await getAuth0UserManagementAccessToken()

      // Call Auth0 Management API to unlink the account
      await unlinkAccountFromPrimaryAuth0User(
        provider,
        secondaryUserId,
        primaryUserId,
        primaryUserToken
      )

      // update the linked accounts
      await updateLinkedAccounts()
    } catch (error) {
      console.error('Error unlinking account:', error)
      if (axios.isAxiosError(error)) {
        console.error('API Error details:', error.response?.data)
      }
    }
  }

  const handleLinkAccount = async (connectionName: string) => {
    let auth0ConnectionName = ''

    switch (connectionName) {
      case 'google':
        auth0ConnectionName = 'google-oauth2'
        break
      case 'facebook':
        auth0ConnectionName = 'facebook'
        break
      default:
        throw new Error(
          'Invalid provider. Please select a valid provider to link.'
        )
    }

    try {
      handleMenuClose()

      const primaryUserId = user?.sub as string
      const primaryUserToken = await getAuth0UserManagementAccessToken()
      const { secondaryUserId, secondaryUserIdToken } =
        await getSecondaryAuth0UserDetails(auth0ConnectionName)

      // validation to prevent linking accounts
      if (primaryUserId === secondaryUserId) {
        throw new Error(
          `Cannot link an account to itself. Please select a different ${connectionName} account.`
        )
      }

      await linkAccountToPrimaryAuth0User(
        secondaryUserIdToken,
        primaryUserId,
        primaryUserToken
      )

      // update the linked accounts
      await updateLinkedAccounts()
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.includes('Cannot link an account')
      ) {
        console.error(error.message)
      } else if (axios.isAxiosError(error)) {
        console.error(
          `Error linking ${connectionName} Account:`,
          error.response?.data
        )
      } else {
        console.error(`Error linking ${connectionName} Account:`, error)
      }
    }
  }

  // LinkedAccountAvatar Component
  const LinkedAccountAvatar = ({
    provider,
    profilePicture,
    backgroundColor,
  }: {
    provider?: string
    profilePicture?: string
    backgroundColor?: string
  }) => {
    let IconComponent

    switch (provider) {
      case 'google-oauth2':
        IconComponent = GoogleIcon
        backgroundColor = '#ff0000a1'
        break
      case 'facebook':
        IconComponent = FacebookOutlined
        backgroundColor = '#0072ffa1'
        break
      default:
        IconComponent = Person
    }

    return (
      <Avatar
        sx={{
          mr: 2,
          backgroundColor: backgroundColor || theme.palette.primary.light,
          width: 40,
          height: 40,
        }}
        src={profilePicture || undefined}
      >
        {!profilePicture && <IconComponent />}
      </Avatar>
    )
  }

  // AccountDetails Component
  const LinkedAccountDetails = ({
    name,
    email,
  }: {
    name: string
    email: string
  }) => (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="body1">{name || 'Google Account'}</Typography>
      <Typography variant="caption" color="text.secondary">
        {email}
      </Typography>
    </Box>
  )

  const LinkedAccountCard = ({ account }: { account: Auth0UserIdentity }) => (
    <Box
      key={account.user_id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        mb: 2,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'grey.200',
        '&:hover': {
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      {/* Profile picture if available for linked user */}
      <LinkedAccountAvatar provider={account.provider} />
      <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
      <LinkedAccountAvatar
        profilePicture={account.profileData?.picture as string}
      />
      {/* Account Details */}
      <LinkedAccountDetails
        name={account.profileData?.name as string}
        email={account.profileData?.email as string}
      />
      {/* Conditionally render delete button for primary and secondary account */}
      {account.primary ? (
        <Typography variant="caption">Primary Account</Typography>
      ) : (
        <Tooltip title={`Unlink ${account.provider} account`} arrow>
          <PrimaryDeleteButton
            onClick={() =>
              handleUnlinkAccount(account.provider, account.user_id)
            }
          >
            <DeleteOutline />
          </PrimaryDeleteButton>
        </Tooltip>
      )}
    </Box>
  )

  return (
    <Box sx={{ py: 4, display: 'flex', alignItems: 'start' }}>
      <Box sx={{ width: '100%' }}>
        <Stack direction="row" justifyContent="space-between">
          {/* Title */}
          <Typography
            variant="tabSection"
            sx={{ fontWeight: 500, alignSelf: 'center' }}
          >
            Linked Accounts
          </Typography>

          <StyledAddIcon onClick={handleMenuOpen} />
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {['google', 'facebook'].map((provider) => (
              <MenuItem
                key={`dropdown-item-${provider}`}
                onClick={() => handleLinkAccount(provider)}
              >
                <Box marginRight={1} padding={0.5}>
                  <PlusIcon />
                </Box>
                Link {provider} account
              </MenuItem>
            ))}
          </Menu>
        </Stack>

        {/* Linked Accounts */}
        <Box sx={{ mt: 2 }}>
          {linkedAccounts.map((account: Auth0UserIdentity) => (
            /* Linked Account Details and Interactions */
            <LinkedAccountCard
              key={`linked-account-card-${account.user_id}`}
              account={account}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default LinkedAccounts
