import { useQueryClient } from '@tanstack/react-query'
import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import {
  ArrowRightLeftIcon,
  Building2Icon,
  MapPinIcon,
  TruckIcon,
  UsersIcon,
} from 'lucide-react'

import DataSharing from '@/components/organization-settings-and-members/organization-data-sharing.tsx'
import DeliveryVehicles from '@/components/organization-settings-and-members/organization-delivery-vehicles.tsx'
import OrganizationDetails from '@/components/organization-settings-and-members/organization-details.tsx'
import Locations from '@/components/organization-settings-and-members/organization-locations.tsx'
import TeamMembers from '@/components/organization-settings-and-members/organization-team-members.tsx'
import a11yProps from '@/components/ui/a11y-props.ts'
import TabLabel from '@/components/ui/base/tab-label.tsx'
import TabPanel from '@/components/ui/base/tab-panel.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

export default function SettingsAndMembersTabs() {
  const { selectedOrganization } = useOrganization()
  const [value, setValue] = useState(0)
  const [highlightId, setHighlightId] = useState(false)

  const queryClient = useQueryClient()

  const refetchOrganization = () => {
    if (selectedOrganization) {
      queryClient.invalidateQueries({ queryKey: ['selected-organization'] })
    }
  }

  const switchToOrganizationTab = (value: boolean) => {
    setValue(0) // Switch to organization tab - index 0
    setHighlightId(value)
  }

  const handleHighlightAnimationComplete = () => {
    setHighlightId(false)
  }

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setHighlightId(false)
  }

  if (!selectedOrganization) {
    return null
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 900,
        paddingLeft: 4,
        py: 4,
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="organization settings tabs"
        sx={{
          width: '33%',
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        {[
          { label: 'Organization', icon: <Building2Icon /> },
          { label: 'Locations', icon: <MapPinIcon /> },
          { label: 'Team Members', icon: <UsersIcon /> },
          { label: 'Delivery Vehicles', icon: <TruckIcon /> },
          { label: 'Data Sharing', icon: <ArrowRightLeftIcon /> },
        ].map((tab, index) => (
          <Tab
            key={index}
            label={
              <TabLabel label={tab.label} icon={tab.icon} variant="tabLabel1" />
            }
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
              padding: 2,
              borderRadius: 2,
            }}
            {...a11yProps(index, 'org-settings')}
          />
        ))}
      </Tabs>

      <TabPanel id="org-settings-details" index={0} value={value}>
        <OrganizationDetails
          highlightId={highlightId}
          onHighlightAnimationComplete={handleHighlightAnimationComplete}
          organization={selectedOrganization}
        />
      </TabPanel>

      <TabPanel id="org-settings-locations" index={1} value={value}>
        <Locations
          organization={selectedOrganization}
          refetch_organization={refetchOrganization}
        />
      </TabPanel>

      <TabPanel id="org-settings-team-members" index={2} value={value}>
        <TeamMembers organization={selectedOrganization} />
      </TabPanel>

      <TabPanel id="org-settings-delivery-vehicles" index={3} value={value}>
        <DeliveryVehicles />
      </TabPanel>

      <TabPanel id="org-settings-data-sharing" index={4} value={value}>
        <DataSharing switchToOrganizationTab={switchToOrganizationTab} />
      </TabPanel>
    </Box>
  )
}
