import { useQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createFileRoute } from '@tanstack/react-router'
import { useDebounce } from '@uidotdev/usehooks'
import { ChangeEvent, useState } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Building2, Search, XIcon } from 'lucide-react'

import GlobalNewButton from '@/components/ui/global-new-button.tsx'
import ContactsSidebar from '@/components/contacts/contacts-sidebar.tsx'
import ContactsTable from '@/components/contacts/contacts-table.tsx'

import { getContacts, searchContacts } from '@/api/contacts.ts'
import { ContactCategories, ContactTypes } from '@/constants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

export const Route = createFileRoute('/_authenticated/contacts/')({
  component: ContactsIndex,
})

const ContactCategoriesMap: { [key: string]: string } = {
  all: 'All Contacts',
  favorite: 'Favorites',
  customers: 'Customers',
  vendors: 'Vendors',
  my_team: 'My Team',
}
const ContactTypeMap: { [key: string]: string } = {
  user: 'People',
  organization: 'Organizations',
}

function ContactsIndex() {
  const { selectedOrganization } = useOrganization()
  const [category, setCategory] = useState<ContactCategories>('all')
  const [contactType, setContactType] = useState(ContactTypes.USER)
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useDebounce(keyword, 500)

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['contacts', { contactType, category }],
    queryFn: () => getContacts(contactType, category, selectedOrganization),
  })

  const { data: searchResults, isLoading: isSearching } = useQuery({
    queryKey: ['contacts', { contactType, category, debouncedKeyword }],
    queryFn: () =>
      searchContacts(
        contactType,
        category,
        debouncedKeyword,
        selectedOrganization
      ),
    enabled: !!debouncedKeyword,
  })

  const handleContactCategoryChange = (category: ContactCategories) => {
    setCategory(category)
  }
  const handleContactTypeChange = (type: ContactTypes) => {
    setContactType(type)
  }

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const clearKeyword = () => {
    setKeyword('')
  }

  const title = `${ContactCategoriesMap[category]} - ${ContactTypeMap[contactType]}`

  return (
    <Box display="flex" sx={{ height: '100%', p: 4 }}>
      {/* Contacts Sidebar */}
      <Box sx={{ width: 250, borderRight: 1, borderColor: 'divider', pr: 4 }}>
        <Box display="flex" alignItems="center" gap={1}>
          <Building2 size={28} />
          <Typography variant="h1">Contacts</Typography>
        </Box>
        <ContactsSidebar
          onCategoryChange={handleContactCategoryChange}
          onContactTypeChange={handleContactTypeChange}
        />
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, pl: 4 }}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h3">
            {title}{' '}
            {(isFetching || isSearching) && <CircularProgress size={16} />}
          </Typography>
          <Box display="flex" gap={2}>
            <GlobalNewButton />
          </Box>
        </Box>

        <Divider />

        {/* Search Bar */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder={`Search ${ContactTypeMap[contactType]}...`}
          value={keyword}
          InputProps={{
            startAdornment: <Search className="mr-2" />,
            endAdornment: keyword && (
              <InputAdornment position="end">
                <IconButton onClick={clearKeyword} edge="end" size="small">
                  <XIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ my: 3 }}
          onChange={handleKeywordChange}
        />

        {keyword ? (
          <ContactsTable
            type={contactType}
            loading={isSearching}
            contacts={searchResults?.results}
          />
        ) : (
          <ContactsTable
            type={contactType}
            loading={isLoading}
            contacts={data?.results}
          />
        )}
      </Box>

      <ReactQueryDevtools initialIsOpen={false} />
    </Box>
  )
}
