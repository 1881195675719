import { useEffect, useState } from 'react'
import { Box, Typography, Chip, Divider } from '@mui/material'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { User, UserProfile } from '@/types.ts'
import ViewEditTimezone from '@/components/account-preferences/account-tab-timezone.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUser } from '@/api/user.ts'
import { UpdateUserTimezoneSchema } from '@/lib/validation-schemas.ts'
import { z } from 'zod'
import AccountUsernamePasswordReset from '@/components/account-preferences/account-tab-username-password-reset.tsx'
import LinkedAccounts from '@/components/account-preferences/account-tab-linked-accounts.tsx'

const AccountTab = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useUserInfo()
  const [timezoneValidationError, setTimezoneValidationError] = useState<
    string | null
  >(null)
  const [userProfile, setUserProfile] = useState<UserProfile>(
    userInfo?.user as UserProfile
  )

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['user-info-organizations'],
      })
    },
  })

  // Set the userProfile when userInfo changes - check with jack
  useEffect(() => {
    setUserProfile(userInfo?.user as UserProfile)
  }, [userInfo])

  const validateTimezoneForm = (requestData: any): boolean => {
    setTimezoneValidationError(null)
    try {
      const schema = UpdateUserTimezoneSchema
      schema.parse(requestData)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setTimezoneValidationError(error.errors[0].message)
      } else {
        setTimezoneValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const submitTimezoneHandler = async (timezone: string) => {
    try {
      setTimezoneValidationError(null)
      const formData = {
        id: userProfile.id,
        timezone: timezone.trim(),
      }

      if (validateTimezoneForm(formData)) {
        await updateUserMutation.mutateAsync(formData as Partial<User>)
      }
    } catch (error) {
      setTimezoneValidationError(
        `Failed to save profile. Please try again. ${error}`
      )
    }
  }

  return (
    <>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="tabHeader">Account</Typography>
        <Box>
          {userInfo?.email_verified ? (
            <Chip label="verified" color="success" />
          ) : (
            <Chip label="unverified" color="error" />
          )}
        </Box>
      </Box>

      <Divider />

      {/* Username and Password */}
      <AccountUsernamePasswordReset username={userProfile.email} />

      <Divider />

      {/* Accounts linked to user */}
      <LinkedAccounts />

      <Divider />

      {/* Timezone */}
      <ViewEditTimezone
        userTimezone={userProfile.timezone}
        submitTimezoneHandler={submitTimezoneHandler}
        validationError={timezoneValidationError}
      />
    </>
  )
}

export default AccountTab
