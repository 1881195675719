import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { BellIcon, Building2Icon, CircleUser, Settings } from 'lucide-react'

import a11yProps from '@/components/ui/a11y-props.ts'
import MyProfileTab from '@/components/account-preferences/my-profile-tab.tsx'
import AccountTab from '@/components/account-preferences/account-tab.tsx'
import AccountOrganizationsTab from '@/components/account-preferences/organizations-tab.tsx'
import NotificationsSettings from '@/components/account-preferences/notifications-tab.tsx'
import TabPanel from '@/components/ui/base/tab-panel.tsx'
import TabLabel from '@/components/ui/base/tab-label.tsx'

type AccountOrganizationsTabsProps = {
  closeModal: () => void
}

export default function AccountPreferencesTabs({
  closeModal,
}: AccountOrganizationsTabsProps) {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 900,
        paddingLeft: 4,
        py: 4,
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="account-preferences tabs"
        sx={{
          width: '33%',
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        {[
          { label: 'My Profile', icon: <CircleUser /> },
          { label: 'Account', icon: <Settings /> },
          { label: 'Organizations', icon: <Building2Icon /> },
          { label: 'Notifications', icon: <BellIcon /> },
        ].map((tab, index) => (
          <Tab
            key={index}
            label={
              <TabLabel label={tab.label} icon={tab.icon} variant="tabLabel1" />
            }
            sx={{
              paddingLeft: 4,
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
            }}
            {...a11yProps(index, 'account-preferences')}
          />
        ))}
      </Tabs>

      <TabPanel id="account-preferences-profile" index={0} value={value}>
        <MyProfileTab />
      </TabPanel>

      <TabPanel id="account-preferences-account" index={1} value={value}>
        <AccountTab />
      </TabPanel>

      <TabPanel id="account-preferences-organizations" index={2} value={value}>
        <AccountOrganizationsTab closeModal={closeModal} />
      </TabPanel>

      <TabPanel id="account-preferences-notifications" index={3} value={value}>
        <NotificationsSettings />
      </TabPanel>
    </Box>
  )
}
