import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { Download } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import { ElasticsearchResponse } from '@/types'

export const Route = createFileRoute('/_authenticated/rfps/')({
  component: RFPSIndex,
})

// Example RFP type
type RFP = {
  id: string
  name: string
}

function RFPSIndex() {
  const [activeTab, setActiveTab] = useState('by-customer')
  const [_searchResults, setSearchResults] =
    useState<ElasticsearchResponse<RFP> | null>(null)

  return (
    <PageLayout title="RFPs" icon={<Download size={36} />}>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'By Customer', value: 'by-customer' },
            { label: 'Plant List', value: 'by-plant-list' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search RFPs...',
          indexName: 'rfps',
          onSearchResults: setSearchResults,
        }}
      />
    </PageLayout>
  )
}
