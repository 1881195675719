import { createFileRoute } from '@tanstack/react-router'
import { ScrollText } from 'lucide-react'
import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Box,
  Stack,
} from '@mui/material'
import { NavigateBefore, NavigateNext, Delete } from '@mui/icons-material'

import PageLayout from '@/components/ui/layouts/page-layout'
// import { PlantListViewer } from '@/components/plants/plant-list-viewer'
// import { PlantTable } from '@/components/plants/plant-table'
// import { PlantActions } from '@/components/plants/plant-actions'

export function PlantListViewer() {
  return (
    <Paper>
      <Box p={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Sherman-ISD-SW.pdf</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton>
              <NavigateBefore />
            </IconButton>
            <Typography>1 of 2</Typography>
            <IconButton>
              <NavigateNext />
            </IconButton>
          </Stack>
        </Stack>
        {/* PDF rendering component */}
      </Box>

      <Box p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
        {['Sherman-ISD-SW.pdf', 'NPM-Plants.pdf'].map((file) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            key={file}
          >
            <Typography>{file}</Typography>
            <Button startIcon={<Delete />} color="error">
              Delete File
            </Button>
          </Stack>
        ))}
      </Box>
    </Paper>
  )
}

export function PlantTable() {
  return (
    <Box mt={2}>
      <Typography variant="h6" mb={2}>
        6 Plants extracted from "Sherman-ISD-SW.pdf"
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>PLANT NAME</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>SPECS</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{/* Plant rows implementation */}</TableBody>
      </Table>
    </Box>
  )
}

export function PlantActions() {
  return (
    <Stack direction="column" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <Select defaultValue="all" sx={{ minWidth: 250 }}>
          <MenuItem value="all">Viewing Plants from: All Files (3)</MenuItem>
        </Select>
        <Button variant="contained">Upload File</Button>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          placeholder="Search Plants..."
          variant="outlined"
        />
        <Button variant="outlined" color="error">
          Delete Selected
        </Button>
        <Button variant="contained">Add Plants</Button>
      </Stack>
    </Stack>
  )
}

export const Route = createFileRoute('/_authenticated/plants/$plantListId')({
  component: PlantListDetail,
})

function PlantListDetail() {
  const { plantListId } = Route.useParams()

  return (
    <PageLayout
      title={`Plant List: ${plantListId}`}
      icon={<ScrollText size={36} />}
      backRoute={{ to: '/plants', label: 'All Plant Lists' }}
    >
      <Stack direction="row" spacing={3}>
        <Box flex={1}>
          <PlantActions />
          <PlantTable />
        </Box>
        <Box width={600}>
          <PlantListViewer />
        </Box>
      </Stack>
    </PageLayout>
  )
}
