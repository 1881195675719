import { type ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface TabLabelProps {
  label: string
  variant?: 'tabLabel1' | 'tabLabel2'
  icon?: ReactNode
}
export default function TabLabel({
  label,
  variant = 'tabLabel1',
  icon,
}: TabLabelProps) {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {icon}
      <Typography variant={variant}>{label}</Typography>
    </Box>
  )
}
