import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState, type SyntheticEvent } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import Snackbar from '@mui/material/Snackbar'

import { Settings } from 'lucide-react'

import SettingsAndMembersTabs from '@/components/organization-settings-and-members/settings-and-members-tabs.tsx'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { usePubNub } from '@/contexts/PubNubContext'

function hasManageOrganizationPermission(
  messageEvent: any,
  currentOrganizationId: string | undefined
) {
  if (!currentOrganizationId) {
    return false
  }

  const organizationMember =
    messageEvent.message.model.organization_members.find(
      (member: any) => member.organization.id === currentOrganizationId
    )

  if (!organizationMember) {
    return false
  }

  return organizationMember.roles.some((role: any) =>
    role.permissions.includes('manage_organization')
  )
}

type SettingsAndMembersModalProps = {
  open: boolean
  onClose: () => void
}
export default function SettingsAndMembersModal({
  open,
  onClose,
}: SettingsAndMembersModalProps) {
  const queryClient = useQueryClient()
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const { userInfo } = useUserInfo()
  const { selectedOrganization } = useOrganization()
  const [showPermissionChangedAlert, setShowPermissionChangedAlert] =
    useState(false)

  useEffect(() => {
    const channel = userInfo?.user.pubsub_channel

    if (!channel) {
      return
    }

    const listener = async (messageEvent: any) => {
      await queryClient.invalidateQueries({
        queryKey: ['user-info-organizations'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
      if (
        selectedOrganization?.id &&
        !hasManageOrganizationPermission(messageEvent, selectedOrganization?.id)
      ) {
        onClose()
        setShowPermissionChangedAlert(true)
      }
    }

    subscribeToChannels([channel], 'user', listener)

    return () => {
      unsubscribeFromChannels([channel], 'user')
    }
  }, [userInfo?.user.pubsub_channel, selectedOrganization?.id])

  const handleCloseAlert = (
    _event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setShowPermissionChangedAlert(false)
  }

  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            width: { xs: '90%', lg: '1100px' },
          }}
        >
          <ModalHeader
            title={'Settings and Members'}
            variant="modalHeader2"
            icon={<Settings size={26} strokeWidth={2.5} />}
            showCloseButton
            onCloseButtonClick={onClose}
          />
          <Divider />
          <SettingsAndMembersTabs />
        </Box>
      </Modal>
      <Snackbar
        open={showPermissionChangedAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="info"
          sx={{ width: '100%' }}
        >
          Your permissions have changed. You no longer have access to this
          organization&apos;s settings.
        </Alert>
      </Snackbar>
    </>
  )
}
