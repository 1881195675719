import { type ReactNode } from 'react'

import Box from '@mui/material/Box'

type TabPanelProps = {
  children?: ReactNode
  id: string
  index: number
  value: number
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, id } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      sx={{ overflowY: 'auto', width: '100%' }}
    >
      {value === index && <Box px={4}>{children}</Box>}
    </Box>
  )
}
