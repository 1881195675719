import { useState, useRef, DragEvent, ChangeEvent } from 'react'
import { useMutation } from '@tanstack/react-query'
import { createPlantList } from '@/api/plants.ts'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { ACCEPTED_FILE_TYPES } from '@/constants.ts'
import UploadFileIcon from '@mui/icons-material/UploadFile'

interface AddPlantListModalProps {
  open: boolean
  onClose: () => void
  onPlantListCreated: (newPlantListId: string) => void
}
export default function AddPlantListModal({
  open,
  onClose,
  onPlantListCreated,
}: AddPlantListModalProps) {
  const { selectedOrganization } = useOrganization()
  const [name, setName] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [validationError, setValidationError] = useState<string | null>('')

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)

    const droppedFiles = Array.from(e.dataTransfer.files)
    const allowedFiles = droppedFiles.filter((file) =>
      ACCEPTED_FILE_TYPES.includes(file.type)
    )

    setFiles((prev) => [...prev, ...allowedFiles])
  }

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files)
      setFiles((prev) => [...prev, ...selectedFiles])
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const createPlantListMutation = useMutation({
    mutationFn: createPlantList,
    onSuccess: async (newPlantList) => {
      setName('')
      setFiles([])
      setValidationError(null)
      onClose()
      onPlantListCreated(newPlantList.id)
    },
  })

  const handleSubmit = async () => {
    if (!selectedOrganization) {
      return
    }
    const plantListRequest = {
      name,
      organization: selectedOrganization.id,
      files,
    }
    await createPlantListMutation.mutateAsync(plantListRequest)
  }

  const handleClose = () => {
    setName('')
    setFiles([])
    setValidationError(null)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="add-plant-list-modal-title"
      aria-describedby="add-plant-list-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="add-plant-list-modal-title" variant="h6" component="h2">
          Add Plant List
        </Typography>
        <TextField
          id="name"
          name="name"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          error={!!validationError}
          helperText={validationError}
          fullWidth
          required
        />

        {files.length === 0 && (
          <>
            <Typography
              id="add-plant-list-modal-description"
              variant="body1"
              gutterBottom
            >
              Upload a Plant List and then assign plants to Jobs, RFPs, Quotes,
              or Orders.
            </Typography>
            <Box
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              sx={{
                border: '2px dashed',
                borderColor: isDragging ? 'primary.main' : 'grey.300',
                borderRadius: 2,
                p: 4,
                textAlign: 'center',
                cursor: 'pointer',
                bgcolor: isDragging ? 'action.hover' : 'background.paper',
                transition: 'all 0.2s ease',
              }}
              onClick={triggerFileInput}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={handleFileSelect}
              />
              <UploadFileIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
              <Typography variant="h6" mt={2}>
                Upload File(s)
              </Typography>
              <Typography variant="body2" color="text.secondary">
                PDF, JPG, PNG, .xlsx, WEBP, GIF, csv, .txt
              </Typography>
            </Box>
          </>
        )}

        {files.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" gutterBottom>
              Selected Files:
            </Typography>
            {files.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  border: '1px solid',
                  borderColor: 'grey.200',
                  borderRadius: 1,
                  mb: 1,
                }}
              >
                <Typography>{file.name}</Typography>
              </Box>
            ))}
          </Box>
        )}
        <Box display="flex" justifyContent="end" gap={1}>
          <Button onClick={handleClose} color="primary">
            Cancel &amp; Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
