import { ContactCategories, ContactTypes } from '@/constants.ts'
import type {
  Contact,
  ContactAddress,
  ContactCountCollection,
  ContactDetails,
  ContactDetailsResponse,
  ContactType,
  ElasticsearchResponse,
  Organization,
  OrganizationContact,
} from '@/types.ts'

import api from './api.ts'

export async function getContacts(
  contactType: ContactTypes,
  category: ContactCategories,
  owner: Organization | null
) {
  let categoryFilter = ''
  if (!owner) {
    throw new Error('Cannot get contacts without an owner organization')
  }
  if (category !== 'all') {
    categoryFilter = `&${category}=true`
  }
  const response = await api.get(
    `/v1/core/contact/search/?owner=${owner.id}&type=${contactType}${categoryFilter}`
  )
  return response.data as ElasticsearchResponse<Contact>
}

export async function searchContacts(
  contactType: ContactTypes,
  category: ContactCategories,
  keyword: string,
  owner: Organization | null
) {
  let categoryFilter = ''
  if (!owner) {
    throw new Error('Cannot search contacts without an owner organization')
  }
  if (category !== 'all') {
    categoryFilter = `&${category}=true`
  }
  const response = await api.get(
    `/v1/core/contact/search/?owner=${owner.id}&type=${contactType}&keyword=${keyword}${categoryFilter}`
  )
  return response.data as ElasticsearchResponse<Contact>
}

export async function searchOrganizationContacts(
  organizationName: string,
  owner: Organization | null
) {
  if (!owner) {
    throw new Error('Cannot search contacts without an owner organization')
  }
  const response = await api.get(
    `/v1/core/contact/search/?owner=${owner.id}&type=organization&organization_name=${organizationName}`
  )
  return response.data as ElasticsearchResponse<OrganizationContact>
}

export async function getContactDetails(id: string) {
  const response = await api.get(`/v1/core/contact/details/${id}`)
  return response.data as ContactDetailsResponse
}

export async function getContactCounts() {
  const response = await api.get('/v1/core/contact/counts/')
  return response.data as ContactCountCollection[]
}

const formatContactDetailsAddress = (contactDetails: ContactDetails) => {
  if (
    Array.isArray(contactDetails.address) &&
    contactDetails.address.length > 0
  ) {
    return contactDetails.address.reduce(
      (acc: object[], contactDetailAddress: ContactAddress) => {
        const addr = contactDetailAddress.address
        if (addr.street || addr.city || addr.state || addr.zip) {
          const street = [addr.street, addr.suite].filter(Boolean).join(', ')
          acc.push({
            ...contactDetailAddress,
            address: {
              id: addr.id,
              street: street,
              city: addr.city,
              state: addr.state,
              zip: addr.zip,
              country: 'United States',
            },
          })
        }
        return acc
      },
      []
    )
  }
  return []
}

export async function createContact({
  contactDetails,
  owner,
}: {
  contactDetails: ContactDetails
  owner: Organization | null
}) {
  if (!owner) {
    throw new Error('Cannot create contact without an owner organization')
  }
  const body: any = contactDetails

  body.owner = owner
  body.address = formatContactDetailsAddress(contactDetails)

  // filter out empty organization names from organization_contact
  if (contactDetails.organization_contact) {
    body.organization_contact = contactDetails.organization_contact.filter(
      (orgContact) => orgContact.organization_name.trim()
    )
  }

  const url =
    contactDetails.type === 'user'
      ? '/v1/core/contact/user/'
      : '/v1/core/contact/organization/'

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export async function updateContact(contact: Contact) {
  const url =
    contact.type === ContactTypes.USER
      ? `/v1/core/contact/user/${contact.id}`
      : `/v1/core/contact/organization/${contact.id}`
  const response = await api.put(url, contact, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data as ContactDetailsResponse
}

export async function updateContactById({
  id,
  contactDetails,
  owner,
}: {
  id: string
  contactDetails: ContactDetails
  owner: Organization | null
}) {
  if (!owner) {
    throw new Error('Cannot update contact without an owner organization')
  }
  const body: any = contactDetails
  body.owner = owner
  body.address = formatContactDetailsAddress(contactDetails)

  const url =
    contactDetails.type === 'user'
      ? `/v1/core/contact/user/${id}`
      : `/v1/core/contact/organization/${id}`

  const response = await api.put(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as ContactDetailsResponse
}

export async function favoriteContact(
  contact: Contact,
  organization_contacts: OrganizationContact[] | undefined
) {
  const body: Contact = {
    ...contact,
    favorite: true,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function unfavoriteContact(
  contact: Contact,
  organization_contacts: OrganizationContact[] | undefined
) {
  const body: Contact = {
    ...contact,
    favorite: false,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function deleteContact(id: string, type: ContactType) {
  const url =
    type === ContactTypes.USER
      ? `/v1/core/contact/user/${id}`
      : `/v1/core/contact/organization/${id}`
  const response = await api.delete(url)
  return response.status
}
