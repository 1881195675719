import { useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'

import { Settings2 } from 'lucide-react'

import AccountPreferencesTabs from '@/components/account-preferences/account-preferences-tabs.tsx'
import ModalHeader from '@/components/ui/base/modal-header.tsx'

type AccountPreferenceModalProps = {
  open: boolean
  onClose: () => void
}
export default function AccountPreferenceModal({
  open,
  onClose,
}: AccountPreferenceModalProps) {
  const [validationError, setValidationError] = useState<string | null>(null)

  const handleClose = () => {
    setValidationError(null)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          boxShadow: 24,
          pt: 2,
          width: { xs: '90%', lg: '1100px' },
        }}
      >
        <ModalHeader
          title={'Account Preferences'}
          variant="modalHeader2"
          icon={<Settings2 size={26} strokeWidth={2.5} />}
          showCloseButton
          onCloseButtonClick={handleClose}
        />
        <Divider />
        <AccountPreferencesTabs closeModal={handleClose} />
        {validationError}
      </Box>
    </Modal>
  )
}
