import { useAuth0 } from '@auth0/auth0-react'
import { grey } from '@mui/material/colors'
import { useState, type MouseEvent } from 'react'

import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { CircleUserRound, LogOut, Settings2 } from 'lucide-react'
import Avatar from '@mui/material/Avatar'
import AccountPreferenceModal from '@/components/ui/modals/account-preferences-modal.tsx'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { useQuery } from '@tanstack/react-query'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import { GCSFile, ImageFile } from '@/types.ts'
import SettingsAndMembersModal from '@/components/ui/modals/settings-and-members-modal.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

type UserMenuProps = {
  expanded: boolean
}

export default function UserMenu({ expanded }: UserMenuProps) {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [accountPreferenceModalOpen, setAccountPreferenceModalOpen] =
    useState(false)
  const open = Boolean(anchorEl)
  const { user } = useAuth0()

  const { userInfo } = useUserInfo()
  const { organizationModalOpen, toggleOrganizationModal } = useOrganization()

  const { data: profilePictureUrl } = useQuery({
    queryKey: [
      'account-profile-picture',
      // userInfo?.user?.id,
      // userInfo?.user?.profile_picture,
    ],
    queryFn: async () => {
      if (!userInfo?.user?.profile_picture?.get_url) {
        return null
      }

      return (await fetchGCSFile(
        userInfo?.user?.profile_picture as GCSFile
      )) as ImageFile
    },
    enabled: !!userInfo?.user, // Only run query when userInfo is available
  })

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAccountPreferenceModal = () => {
    setAnchorEl(null)
    setAccountPreferenceModalOpen(!accountPreferenceModalOpen)
  }

  return (
    <>
      <ListItem
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          ':hover': {
            bgcolor: grey[700],
          },
        }}
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expanded ? 2 : 'auto',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          {user && (user.picture || profilePictureUrl?.url) ? (
            <Avatar
              src={profilePictureUrl?.url || user.picture}
              alt={user.name}
            />
          ) : (
            <CircleUserRound />
          )}
        </ListItemIcon>
        {expanded && <ListItemText primary="My Account" />}
      </ListItem>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 0, vertical: -135 }}
        slotProps={{ paper: { sx: { width: 280 } } }}
        TransitionComponent={Fade}
      >
        <Typography px={2} fontWeight="bold" mb={1}>
          {user && user.name ? user.name : 'My Account'}
        </Typography>
        <MenuItem onClick={handleAccountPreferenceModal}>
          <ListItemIcon>
            <Settings2 size={16} />
          </ListItemIcon>
          <ListItemText primary="Account Preferences" />
        </MenuItem>
        <Divider />
        <MenuItem
          component="div"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <ListItemIcon>
            <LogOut size={16} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      <AccountPreferenceModal
        open={accountPreferenceModalOpen}
        onClose={handleAccountPreferenceModal}
      />
      <SettingsAndMembersModal
        open={organizationModalOpen}
        onClose={() => toggleOrganizationModal(false)}
      />
    </>
  )
}
